import React from "react";

function Plumbing(props) {
    return (
        <section className="service-page">
            <head>
                <title>Plumbing Services at Alta Property Services</title>
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Certified / Licensed & Insured, Frozen pipe repairs,Drain cleaning, 
                jetting & rodding,Pipe repair and replacement,Full scale rehab & repining services,
                Commercial fire system draining & testing,Water heater replacement,
                Sewer / drain and cleanout piping,Boiler system repairs & replacement,24/7 services"/>
            </head>
            <section className="service-section">
                <h2 className="service-heading">Plumbing and Sewer Repair</h2>
                <p className="service-description">
                    Our team of licensed plumbers are ready to handle any plumbing situation which may arise, large or small.
                    With years of experience and state of the art daignostic equipment we are able to locate issues and provide
                    cost effective solutions. We can handle  small issues like clogged drains up to digging out and replcaing sewer lines.
                </p>
                <ul  className="snow-removal-list">
                    <li>Certified / Licensed & Insured </li>

                    <li>Frozen pipe repairs</li>

                    <li>Drain cleaning, jetting & rodding</li>

                    <li>Pipe repair and replacement</li>

                    <li>Full scale rehab & repining services</li>

                    <li>Commercial fire system draining & testing</li>

                    <li>Water heater replacement</li>

                    <li>Sewer / drain and cleanout piping.</li>

                    <li>Boiler system repairs & replacement</li>

                    <li>24/7 services</li>
                </ul></section>
            <section className="image-gallery">
                <img
                    src="images/burst-pipe-orig.jpg"
                    alt="Certified / Licensed & Insured, Frozen pipe repairs,Drain cleaning, 
                jetting & rodding,Pipe repair and replacement,Full scale rehab & repining services,
                Commercial fire system draining & testing,Water heater replacement,
                Sewer / drain and cleanout piping,Boiler system repairs & replacement,24/7 services"
                />

                <img
                    src="images/flood Basement02.jpg"
                    alt="Certified / Licensed & Insured, Frozen pipe repairs,Drain cleaning, 
                jetting & rodding,Pipe repair and replacement,Full scale rehab & repining services,
                Commercial fire system draining & testing,Water heater replacement,
                Sewer / drain and cleanout piping,Boiler system repairs & replacement,24/7 services"
                />
                <img
                    src="images/istockphoto-172441142-612x612.jpg"
                    alt="Certified / Licensed & Insured, Frozen pipe repairs,Drain cleaning, 
                jetting & rodding,Pipe repair and replacement,Full scale rehab & repining services,
                Commercial fire system draining & testing,Water heater replacement,
                Sewer / drain and cleanout piping,Boiler system repairs & replacement,24/7 services"
                />
                <img
                    src="images/Plumbing-Evaluation-scaled-1.jpg"
                    alt="Certified / Licensed & Insured, Frozen pipe repairs,Drain cleaning, 
                jetting & rodding,Pipe repair and replacement,Full scale rehab & repining services,
                Commercial fire system draining & testing,Water heater replacement,
                Sewer / drain and cleanout piping,Boiler system repairs & replacement,24/7 services"
                />

            </section>
        </section>
    );
}

export default Plumbing;