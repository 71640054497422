import React from "react";

function Careers(props) {
  return (
    <section>
      <head>
        <title>Careers at Alta Property Services</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Read about Careers at ALTA Property Services"  />
      </head>
      <h3>Careers</h3>
      <h5>NO POSITIONS AVAILABLE AT THIS TIME.</h5>
    </section>
  );
}

export default Careers;
